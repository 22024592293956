@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('./assets/fonts/NotoKufiArabic.woff2') format('woff2'),
  url('./assets/fonts/NotoKufiArabic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Kufi Arabic';
  src: url('./assets/fonts/NotoKufiArabic-Bold.woff2') format('woff2'),
  url('./assets/fonts/NotoKufiArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Arial, sans-serif;
  background-color: #ddd !important;
}

.notice {
  position: relative;
  background: #F9F9F9;
  padding: 1em 1em 1em 3em;
  border-left: 4px solid #DDD;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.125);
  color: black;
}

.notice:before {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 5px;
  background-color: #DDD;
  color: #FFF;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

.warning {
  border-color: #FFDC00;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.warning:before {
  content: "!";
  background-color: #FFDC00;
}

.success {
  border-color: green;
}

.success:before {
  content: "✓";
  background-color: green;
}

.App {
  width: 100%;
  height: 100%;
}

#container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

#controls {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
}

#social-posts {
  padding: 20px;
}

#social-posts.list {
  height: calc(100% - 345px);
  overflow: auto;
  padding-top: 56px;
}
#social-posts.list:after {
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  float: left;
}

.posts-list {
}

.play-container {
  position: relative;
}

.social-post .img-play {
  position: absolute;
  top: 14px;
  left: 33px;
  width: 50px !important;
  height: auto !important;
}

.icon-play-circle {
  color: white;
  font-size: 70px;
}

.social-post {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.social-post .preview {
  float: left;
  width: 100%;
  background-color: white;
}

.social-post .preview img {
  display: block;
  width: auto;
  height: auto;
  max-width: 110px;
  max-height: 73px;
  margin: 0 auto;
}

.social-post .list-img-container {
  float: left;
  width: 110px;
  height: 73px;
  background-color: black;
  margin-right: 10px;
  position: relative;
}

.social-post .preview p {
  padding: 10px;
  line-height: 18px;
  color: black;
}

.social-post .detail {
  position: relative;
  text-align: center;
}

.social-post .detail img, .social-post .detail video, .social-post .detail iframe {
  display: block;
  max-height: 260px;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.social-post .detail p {
  padding: 20px 10px 0px;
  text-align: center;
}

.social-post .detail .close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  background-color: black;
  color: white;
  text-decoration: none;
  line-height: 30px;
}

.btn-download {
  position: relative;
  display: inline-block;
  background-color: white;
  border-radius: 25px;
  margin: 10px auto;
  color: black;
  padding: 10px 30px;
  text-align: left;
}

.btn-download img {
  float: left;
}

.btn-download span {
  float: left;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
}

.btn-contact-whatsapp {
  position: relative;
  display: inline-block;
  background-color: white;
  margin: 10px auto;
  color: black;
  width: 246px;
  height: 56px;
  text-align: center;
}

.btn-contact-whatsapp img {
  padding-top: 8px;
}

.share-block {
  border-radius: 30px;
  margin: 20px auto 0;
}

h2.section-title {
  font-size: 25px;
  text-align: center;
}

.share-block h2.section-title {
  border: 1px solid white;
  background: url("./assets/img/share-icon.png") no-repeat 10px;
}

.share-block:after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
  float: left;
}

.btn-share {
  display: inline-block;
  width: 76px;
  height: 76px;
}

.share-block-container {
  margin: 0 auto 0;
  max-width: 240px;
}

.btn-share:nth-child(2),
.btn-share:nth-child(4) {
  margin-left: 20px;
}

.btn-share:nth-child(1),
.btn-share:nth-child(2) {
  margin-bottom: 20px;
}

.btn-request-test-drive, .btn-send-me-via-email {
  position: relative;
  display: inline-block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: white;
  color: #d10a2b;
  border: none;
  border-radius: 19px;
}
.btn-send-me-via-email {
  color: black;
}

.btn-request-test-drive:focus, .btn-send-me-via-email:focus {
  outline: none;
}
.btn-request-test-drive.disabled, .btn-send-me-via-email.disabled {
  opacity: .5;
}

.btn-request-test-drive a, .btn-send-me-via-email a {
  display: block;
  height: 100%;
  width: 100%;
}

.btn-request-test-drive img, .btn-send-me-via-email img {
  float: left;
  padding-top: 5px;
}

.btn-request-test-drive span, .btn-send-me-via-email span {
  float: left;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 16px;
  line-height: 18px;
  text-align: left;
}

.btn-request-test-drive span {
  padding-left: 0;
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: 99;
}

.alert p {
  line-height: 40px;
  padding: 0 10px;
  color: #111;
  font-size: 20px;
}

.alert.alert-success {
  background: rgba(0, 255, 0, .6);
}

.alert.alert-error {
  background: rgba(255, 0, 0, .6);
}

.mainStyle {
  position: relative;
  background-size: auto;
  background-position: center calc(100% - 50px);
  background-repeat: no-repeat;
  height: 100%
}

.header {
  position: relative;
  text-align: center;
  background: no-repeat center;
  height: 94px;
  width: 100%;
  z-index: 9;
  background-position-y: 0;
  background-size: 100%
}

.footer {
  background: no-repeat center bottom;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 125px;
  width: 100%;
  -webkit-filter: drop-shadow(0px -5px 15px rgba(0, 0, 0, .8));
  filter: drop-shadow(0px -5px 15px rgba(0, 0, 0, .8));
}

#nav-elements {
  position: relative;
  z-index: 2;
}

#shaking-phone {
  position: relative;
  z-index: 1;
}
.has-items .social-post {
 margin-top: 40px;
}

.btn-fermer {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  text-align: center;
  border: none;
  background: none;
}

.btn-fermer p {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 12px;
}

.btn-fermer img {
  display: inline-block;
}

#mail-sent-success-notif {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}

#mail-sent-success-notif p {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 12px;
}

#mail-sent-success-notif img {
  display: inline-block;
}

.arrow-left {
  padding: 10px;
  cursor: pointer;
}

.shake-get-it {
  cursor: pointer;
  margin-top: 20px;
}

.arrow-right {
  padding: 10px;
  cursor: pointer;
}

.shake-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.shake-container .shake-img {
  width: 12%;
}

.shake-title {
  color: white;
}

.shake-description {
  font-size: 14px;
  line-height: 1.6;
  padding-top: 36px;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  margin-top: 40px;
  text-align: center;
}

.navigation-message {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
}

@-webkit-keyframes shakyPhone {
  0% {
    -webkit-transform: rotate(0deg) skewY(9deg) translateX(0px);
    transform: rotate(0deg) skewY(9deg) translateX(0px);
  }
  2% {
    -webkit-transform: rotate(-3deg) skewY(9deg) translateX(-6px);
    transform: rotate(-3deg) skewY(9deg) translateX(-6px);
  }
  6% {
    -webkit-transform: rotate(4.5deg) skewY(9deg) translateX(10px);
    transform: rotate(4.5deg) skewY(9deg) translateX(10px);
  }
  10% {
    -webkit-transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
  }
  14% {
    -webkit-transform: rotate(4.5deg) skewY(9deg) translateX(10px);
    transform: rotate(4.5deg) skewY(9deg) translateX(10px);
  }
  18% {
    -webkit-transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
  }
  22% {
    -webkit-transform: rotate(3deg) skewY(9deg) translateX(6px);
    transform: rotate(3deg) skewY(9deg) translateX(6px);
  }
  25% {
    -webkit-transform: rotate(-1deg) skewY(9deg) translateX(-2px);
    transform: rotate(-1deg) skewY(9deg) translateX(-2px);
  }
  27% {
    -webkit-transform: rotate(0deg) skewY(9deg) translateX(0px);
    transform: rotate(0deg) skewY(9deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) skewY(9deg) translateX(0px);
    transform: rotate(0deg) skewY(9deg) translateX(0px);
  }
}

@keyframes shakyPhone {
  0% {
    -webkit-transform: rotate(0deg) skewY(9deg) translateX(0px);
    transform: rotate(0deg) skewY(9deg) translateX(0px);
  }
  2% {
    -webkit-transform: rotate(-3deg) skewY(9deg) translateX(-6px);
    transform: rotate(-3deg) skewY(9deg) translateX(-6px);
  }
  6% {
    -webkit-transform: rotate(4.5deg) skewY(9deg) translateX(10px);
    transform: rotate(4.5deg) skewY(9deg) translateX(10px);
  }
  10% {
    -webkit-transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
  }
  14% {
    -webkit-transform: rotate(4.5deg) skewY(9deg) translateX(10px);
    transform: rotate(4.5deg) skewY(9deg) translateX(10px);
  }
  18% {
    -webkit-transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
    transform: rotate(-4.5deg) skewY(9deg) translateX(-10px);
  }
  22% {
    -webkit-transform: rotate(3deg) skewY(9deg) translateX(6px);
    transform: rotate(3deg) skewY(9deg) translateX(6px);
  }
  25% {
    -webkit-transform: rotate(-1deg) skewY(9deg) translateX(-2px);
    transform: rotate(-1deg) skewY(9deg) translateX(-2px);
  }
  27% {
    -webkit-transform: rotate(0deg) skewY(9deg) translateX(0px);
    transform: rotate(0deg) skewY(9deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) skewY(9deg) translateX(0px);
    transform: rotate(0deg) skewY(9deg) translateX(0px);
  }
}

@-webkit-keyframes shakyIllu {
  0% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  1% {
    -webkit-transform: rotate(-3deg) translateX(-12px);
    transform: rotate(-3deg) translateX(-12px);
  }
  3% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  5% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  7% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  9% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  11% {
    -webkit-transform: rotate(3deg) translateX(12px);
    transform: rotate(3deg) translateX(12px);
  }
  13% {
    -webkit-transform: rotate(-1deg) translateX(-4px);
    transform: rotate(-1deg) translateX(-4px);
  }
  15% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
}

@keyframes shakyIllu {
  0% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  1% {
    -webkit-transform: rotate(-3deg) translateX(-12px);
    transform: rotate(-3deg) translateX(-12px);
  }
  3% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  5% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  7% {
    -webkit-transform: rotate(4.5deg) translateX(20px);
    transform: rotate(4.5deg) translateX(20px);
  }
  9% {
    -webkit-transform: rotate(-4.5deg) translateX(-20px);
    transform: rotate(-4.5deg) translateX(-20px);
  }
  11% {
    -webkit-transform: rotate(3deg) translateX(12px);
    transform: rotate(3deg) translateX(12px);
  }
  13% {
    -webkit-transform: rotate(-1deg) translateX(-4px);
    transform: rotate(-1deg) translateX(-4px);
  }
  15% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
  100% {
    -webkit-transform: rotate(0deg) translateX(0px);
    transform: rotate(0deg) translateX(0px);
  }
}

@-webkit-keyframes floatToPhone {
  0% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  20% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  40% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  65% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  70% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 0;
  }
  80% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  85% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  100% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes floatToPhone {
  0% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  20% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  40% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  65% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 1;
  }
  70% {
    margin-top: 40%;
    -webkit-transform: scale(0.22);
    transform: scale(0.22);
    opacity: 0;
  }
  80% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  85% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  100% {
    margin-top: 5%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes shakyElement {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  2% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  6% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  10% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  14% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  18% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  22% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  25% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  27% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes shakyElement {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  2% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  6% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  10% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  14% {
    -webkit-transform: translateX(7px);
    transform: translateX(7px);
  }
  18% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  22% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  25% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
  27% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#mainInteraction {
  height: 1000px;
  -webkit-transform: skewY(-9deg);
  -ms-transform: skewY(-9deg);
  transform: skewY(-9deg);
  margin-top: -900px;
  z-index: 0;
  position: relative;
  text-align: center;
  z-index: 2;
}

.bar {
  height: 85px;
  width: 100%;
  max-height: 85px;
}

.bar.red {
  background-color: #fff;
}

.bar.white {
  background-color: #fff;
}

.bar.contain {
  width: auto;
  display: inline-block;
}

.bar.baseline {
  position: absolute;
  top: 22px;
  margin-left: -166px;
}

#shakeVisual, #fallbackVisual {
  position: absolute;
  visibility: visible;
  bottom: -28px;
  width: 100%;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

#shakeVisual .bar, #fallbackVisual .bar {
  overflow: hidden;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

#shakeVisual .bar span.visualText, #shakeVisual .bar a.visualText, #fallbackVisual .bar span.visualText, #fallbackVisual .bar a.visualText {
  position: relative;
  color: #fff;
  font-size: 62px;
  font-weight: 800;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  line-height: 85px;
  margin: 0;
  text-align: left;
  opacity: 1;
}

#shakeVisual .bar span.visualText.shaky, #shakeVisual .bar a.visualText.shaky, #fallbackVisual .bar span.visualText.shaky, #fallbackVisual .bar a.visualText.shaky {
  -webkit-animation-name: shakyElement;
  animation-name: shakyElement;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  color: black;
}

#shakeVisual .bar span.visualText.centerText, #shakeVisual .bar a.visualText.centerText, #fallbackVisual .bar span.visualText.centerText, #fallbackVisual .bar a.visualText.centerText {
  text-align: center;
  padding: 0 20px;
  z-index: 29;
}

#shakeVisual .bar span.visualText.barOverlay, #shakeVisual .bar a.visualText.barOverlay, #fallbackVisual .bar span.visualText.barOverlay, #fallbackVisual .bar a.visualText.barOverlay {
  z-index: 15;
}

#shakeVisual .bar span.visualText #textGetIt, #shakeVisual .bar a.visualText #textGetIt, #fallbackVisual .bar span.visualText #textGetIt, #fallbackVisual .bar a.visualText #textGetIt {
  margin-right: 12px;
}

#shakeVisual .bar span.visualText #textIt, #shakeVisual .bar a.visualText #textIt, #fallbackVisual .bar span.visualText #textIt, #fallbackVisual .bar a.visualText #textIt {
  margin-left: 20px;
  margin-right: 20px;
}

#shakeVisual .bar #phoneIcon, #fallbackVisual .bar #phoneIcon {
  width: 120px;
  vertical-align: middle;
  position: absolute;
  left: 50%;
  margin-left: -60px;
  top: -64px;
  opacity: 1;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}

#shakeVisual .bar #phoneIcon.shaky, #fallbackVisual .bar #phoneIcon.shaky {
  -webkit-animation-name: shakyPhone;
  animation-name: shakyPhone;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

#shakeVisual.hidden .bar, #fallbackVisual.hidden .bar {
  max-height: 0px;
}

#shakeVisual.hidden .bar .visualText, #fallbackVisual.hidden .bar .visualText {
  opacity: 0;
}

#shakeVisual.hidden .bar #phoneIcon, #fallbackVisual.hidden .bar #phoneIcon {
  top: 0px;
  opacity: 0;
}

#fallbackVisual {
  bottom: 0px;
}

@media only screen and (max-width: 650px) {
  form input, .step input {
    margin-bottom: 10px;
    font-size: 18px;
    letter-spacing: 0.1px;
    font-weight: 700;
  }

  form input[type='checkbox'], .step input[type='checkbox'] {
    width: 35px;
    height: 35px;
    margin-right: 7px;
  }

  form input[type='checkbox']:before, .step input[type='checkbox']:before {
    width: 35px;
    height: 35px;
  }

  form input[type='text'], form input[type='email'], .step input[type='text'], .step input[type='email'] {
    height: 35px;
    padding: 10px;
  }

  #textRow {
    margin-top: 20px;
  }

  #textRow p {
    max-height: 50px;
    margin-top: 60px;
    margin-bottom: 32px;
  }

  #skipLink {
    font-size: 18px;
    padding: 10px;
    letter-spacing: 0.1px;
    line-height: 18px;
  }

  #mainInteraction {
    margin-top: -950px;
  }

  #mediaContainer {
    margin-bottom: 105px;
  }

  #mediaContainer .mediaBox {
    text-decoration: none;
    height: 75px;
    max-height: 75px;
    margin-bottom: 15px;
  }

  #mediaContainer .mediaBox.outside {
    margin-top: -90px;
  }

  #mediaContainer .mediaBox .deleteBox {
    width: 75px;
    background-size: 11px;
  }

  #mediaContainer .mediaBox .imageBox {
    height: 75px;
    width: 75px;
    border: 5px solid #ff3f3f;
  }

  #mediaContainer .mediaBox .textBox {
    padding: 9px;
    margin-left: 75px;
  }

  #mediaContainer .mediaBox .textBox h2 {
    margin-bottom: 4px;
    max-height: 36px;
  }

  #mediaContainer .mediaBox .textBox p.social_icon {
    font-size: 18px;
  }

  #mediaContainer .mediaBox .textBox p.social_icon:before {
    width: 17px;
    height: 17px;
    margin-right: 2px;
    top: 2px;
  }

  #mediaContainer .mediaBox .textBox p.link_sf {
    color: #222;
  }

  #mediaContainer .mediaBox .textBox p.link_sf:before {
    margin-top: -4px;
    width: 21px;
    height: 21px;
    top: 3px;
    margin-right: -1px;
    left: -2px;
  }

  .slantedButton {
    bottom: -32px;
    font-size: 31px;
    padding: 0px 16px;
  }

  .slantedButton.goButton {
    width: 75px;
    margin-left: -37px;
  }

  .slantedButton.sendButton {
    width: 155px;
    margin-left: -77px;
  }

  .slantedButton.hiddenRight {
    -webkit-transform: translate3d(500px, 0, 0);
    transform: translate3d(500px, 0, 0);
  }

  .slantedButton.hiddenLeft {
    -webkit-transform: translate3d(-1000px, 0, 0);
    transform: translate3d(-1000px, 0, 0);
  }

  #intro h2, #intro p, #intro label, .lower.container h2, .lower.container p, .lower.container label, .bottom.container h2, .bottom.container p, .bottom.container label {
    font-size: 18px;
    letter-spacing: 0.1px;
    line-height: 18px;
  }

  #intro h2.doubleHeight, .lower.container h2.doubleHeight, .bottom.container h2.doubleHeight {
    line-height: 36px;
  }

  .lower.container, .bottom.container {
    margin-top: 50px;
  }

  .bottom.container {
    margin-top: 45px;
  }

  #basketBox {
    height: 75px;
    background-color: #fff;
  }

  #basketBox .iconBox {
    height: 75px;
    width: 75px;
  }

  #basketBox .formBox {
    margin-left: 75px;
  }

  #basketBox .formBox form {
    margin-top: 20px;
  }

  #basketBox #basketSendButton {
    height: 35px;
    width: 35px;
    top: 0;
    z-index: 12;
  }

  .bar {
    height: 42px;
    max-height: 42px;
  }

  .bar.baseline {
    top: 10px;
    margin-left: -84px;
  }

  #shakeVisual, #fallbackVisual {
    bottom: -18px;
  }

  #shakeVisual .red.bar span.visualText, #shakeVisual .red.bar a.visualText, #fallbackVisual .red.bar span.visualText, #fallbackVisual .red.bar a.visualText {
    font-size: 31px;
    letter-spacing: 0.1px;
    line-height: 42px;
  }

  #shakeVisual .red.bar span.visualText.leftText, #shakeVisual .red.bar a.visualText.leftText, #fallbackVisual .red.bar span.visualText.leftText, #fallbackVisual .red.bar a.visualText.leftText {
    margin-left: 0;
    margin-right: 40px;
  }

  #shakeVisual .red.bar span.visualText.rightText, #shakeVisual .red.bar a.visualText.rightText, #fallbackVisual .red.bar span.visualText.rightText, #fallbackVisual .red.bar a.visualText.rightText {
    margin-left: 20px;
    margin-right: 5px;
  }

  #shakeVisual .red.bar span.visualText.centerText, #shakeVisual .red.bar a.visualText.centerText, #fallbackVisual .red.bar span.visualText.centerText, #fallbackVisual .red.bar a.visualText.centerText {
    padding: 0 10px;
  }

  #shakeVisual .red.bar span.visualText #textGetIt, #shakeVisual .red.bar a.visualText #textGetIt, #fallbackVisual .red.bar span.visualText #textGetIt, #fallbackVisual .red.bar a.visualText #textGetIt {
    margin-right: 6px;
  }

  #shakeVisual .red.bar span.visualText #textIt, #shakeVisual .red.bar a.visualText #textIt, #fallbackVisual .red.bar span.visualText #textIt, #fallbackVisual .red.bar a.visualText #textIt {
    margin-left: 10px;
    margin-right: 10px;
  }

  #shakeVisual .red.bar #phoneIcon, #fallbackVisual .red.bar #phoneIcon {
    width: 60px;
    margin-left: -30px;
    top: -32px;
  }

  #toast {
    height: 30px;
    max-height: 30px;
  }

  #toast p {
    font-size: 18px;
  }

  .closeButton {
    width: 42px;
    height: 42px;
  }

  #fallbackVisual {
    bottom: 0;
  }

  #overlay .container p {
    font-size: 18px;
    margin-top: 60px;
    margin-bottom: 10px;
    letter-spacing: 0.1px;
    line-height: 18px;
  }

  #overlay .container .titleBox {
    margin-top: 90px;
  }

  #overlay .container .titleBox span.titleText {
    font-size: 31px;
    letter-spacing: 0.1px;
    line-height: 42px;
  }

  #overlay .container .titleBox .closeButton {
    margin-left: 10px;
  }

  #overlay #illuBox #illuMedia {
    width: 54px;
    height: 54px;
    left: 50%;
    top: 15%;
    margin-left: -27px;
  }

  #overlay h4 {
    font-size: 18px;
  }

  #overlay p,
  #overlay li {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-top: 50px;
  }
}

.used-cars-filter {
  position: relative;
  z-index: 2;
}

.carousel-control-next, .carousel-control-prev {
  height: 50px;
  transform: translateY(-50%);
  top: 50%;
}

@media only screen and (max-width: 520px) {
  #selfieContainer {
    margin-top: -100px;
  }

  .selfie #textRow {
    margin-top: 12px;
  }

  .selfie #textRow p {
    margin-top: 12px;
  }

  #sharingRow {
    margin-top: 45px;
  }

  #sharingRow.hidden #sharingContainer {
    -webkit-transform: translateX(-400px);
    -ms-transform: translateX(-400px);
    transform: translateX(-400px);
  }

  #sharingContainer span {
    font-size: 24px;
    line-height: 60px;
  }

  #sharingContainer .sharingArrow {
    border-bottom: 1px solid #fff;
  }

  #sharingContainer .sharingArrow:before {
    right: -6px;
    top: -10px;
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 300px) {
  #sharingContainer span {
    font-size: 16px;
    line-height: 35px;
  }
}
